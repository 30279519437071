.app {
	height: 100vh;
	width: 100vw;
}

.app .inputDefault {
	border: none;
	box-shadow: none;
	outline: none;
	border-radius: 4px;
	height: 50px;
	width: 100%;
	background-color: transparent;
	border: 2px solid var(--brand-third);
	color: var(--brand-primary);
	padding-left: 10px;
	padding-right: 55px;
	font-weight: 300;
	border-radius: 60px;
}

.app .buttonDefaultApp {
	border: none;
	border-radius: 4px;
	cursor: pointer;
	background-color: var(--brand-secondary);
	outline: none;
	box-shadow: none;
	padding: 4px 12px;
	color: var(--light-one);
	margin: 2px;
	height: 50px;
	text-overflow: ellipsis;
	display: inline-block;
	white-space: nowrap;
	user-select: none;
}

.app .linkDefault {
	color: var(--brand-primary);
	font-size: 14px;
	font-weight: 300;
	text-decoration: none;
	transition: color .8s;
}

.app .linkDefault:hover {
	color: var(--brand-third);
	transition: color .8s;
}

.app .headerApp {
	padding: 0;
    z-index: 400;
    height: 100px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	vertical-align: middle;
	position: relative;
}

.app .headerApp .title {
	font-family: "Dancing Script", cursive;
	font-optical-sizing: auto;
    color: var(--brand-primary);
    font-weight: 600;
	margin: 0;
	user-select: none;
}
	
.app .bodyApp {
	width: 100%;
	height: calc(100vh - 100px - 40px);
	display: flex;
	justify-content: center;
	align-items: center;
	vertical-align: middle;
	flex-direction: row;
	background-color: var(--light-one);
}

.app .bodyApp .colPlayBodyApp {
	display: flex;
	justify-content: center;
	align-items: center;
	vertical-align: middle;
	flex-direction: column;
}

.app .bodyApp .colPlayBodyApp.middle {
	padding: 10px;
}

.app .bodyApp .colPlayBodyApp .allCountriesSearchBodyApp {
	height: 100px;
	width: 100%;
	margin-left: 50px;
	margin-right: 50px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	vertical-align: middle;
	flex-direction: row;
	position: relative;
}

.app .bodyApp .colPlayBodyApp .allCountriesSearchBodyApp .allButtonCountriesApp {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	vertical-align: middle;
	width: calc(100% - 100px);
	overflow-x: scroll
}

.app .bodyApp .colPlayBodyApp .allCountriesSearchBodyApp .allButtonCountriesApp .buttonCountryBodyApp {
	overflow: initial;
}

.app .bodyApp .colPlayBodyApp .allCountriesSearchBodyApp .allButtonCountriesApp .buttonCountryBodyApp.active {
	background-color: var(--brand-third);
}

.app .bodyApp .colPlayBodyApp .allCountriesSearchBodyApp .buttonStepApp {
	width: 50px;
	border-radius: 50%;
}

.app .bodyApp .colPlayBodyApp .areaSearchBodyApp {
	width: 100%;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	vertical-align: middle;
	margin-top: 25px;
}

.app .bodyApp .colPlayBodyApp .areaSearchBodyApp .buttonSearchBodyApp {
	border-radius: 50%;
	width: 50px;
	background-color: transparent;
	position: absolute;
	right: 10px;
}

.app .bodyApp .colPlayBodyApp .areaSearchBodyApp .buttonSearchBodyApp .iconButtonSeachBodyApp {
	color: var(--brand-third);
}

.app .bodyApp .colPlayBodyApp .allStationsBodyApp {
	display: flex;
	flex-direction: row;
	align-items: center;
	vertical-align: middle;
	width: 100%;
    overflow: scroll;
}

.app .bodyApp .colPlayBodyApp .allStationsBodyApp .scrollStationsBodyApp {
	display: flex;
	flex-direction: row;
}

.app .bodyApp .colPlayBodyApp .allStationsBodyApp .scrollStationsBodyApp .buttonStationBodyApp {
	position: relative;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	vertical-align: middle;
	text-align: center;
	width: 120px;
	height: 120px;
}

.app .bodyApp .colPlayBodyApp .allStationsBodyApp .scrollStationsBodyApp .buttonStationBodyApp .labelButtonStationsBodyApp {
	width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	margin-top: 5px;
}

.app .bodyApp .colPlayBodyApp .allStationsBodyApp .scrollStationsBodyApp .buttonStationBodyApp .helpLabelButtonStationsBodyApp {
	position: absolute;
	top: 10px;
	left: 10px;
	font-size: 12px;
	font-weight: 400;
	background-color: var(--brand-primary);
	opacity: 0;
	transition: opacity 1.5s;
	z-index: 100;
	padding: 2px 4px;
	border-radius: 2px;
	color: var(--light-one);
}

.app .bodyApp .colPlayBodyApp .allStationsBodyApp .scrollStationsBodyApp .buttonStationBodyApp:hover .helpLabelButtonStationsBodyApp {
	opacity: 1;
}

.app .bodyApp .colPlayBodyApp .allStationsBodyApp .scrollStationsBodyApp .buttonStationBodyApp:hover {
	background-color: var(--brand-third);
	color: var(--brand-primary);
	transition: background-color 1.5s;
	transition: color .3s;
	font-weight: 600;
}

.app .bodyApp .colPlayBodyApp .allStationsBodyApp .scrollStationsBodyApp .buttonStationBodyApp.active {
	background-color: var(--brand-third);
	color: var(--brand-primary);
	font-weight: 600;
}

.app .bodyApp .colPlayBodyApp .allStationsBodyApp .scrollStationsBodyApp .buttonStationBodyApp .imageStationsBodyApp {
	width: 50%;
	border-radius: 5px;
}

.app .bodyApp .colPlayBodyApp .allStationsBodyApp .scrollStationsBodyApp .buttonStationBodyApp .numberButtonStationsBodyApp {
	color: var(--light-one);
	position: absolute;
	bottom: 5px;
	left: 5px;
	font-size: 12px;
	font-weight: 300;
}

.app .bodyApp .colPlayBodyApp .allStationsBodyApp .scrollStationsBodyApp .buttonStationBodyApp.active .numberButtonStationsBodyApp {
	color: var(--brand-primary);
	font-weight: 900;
}

.app .gradient {
	background: 2px linear-gradient(90deg, #9ad15e, #31b1e7);
    height: 4px;
    width: 100%;
    content: "";
    display: block;
}

.app .footerApp {
	padding: 0;
    margin: 0;
    background-image: linear-gradient(90deg, #9ad15e, #31b1e7);
    height: 40px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

.app .footerApp .linkDefault {
	color: var(--brand-primary);
    margin: 0;
    padding: 0;
    font-weight: 400;
    font-size: 10px;
}

.app .footerApp .linkDefault:hover {
	color: var(--brand-third);
	transition: color .8s;
}

@media (max-width: 767px) {
	.app .bodyApp {
		flex-direction: column;
		overflow: scroll;
	}
}