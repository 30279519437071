:root {
	--brand-primary: #353535;
	--brand-secondary: #949494;
	--brand-third: #D8B74D;

	--light-one: #f2f2f2;
	--light-two: #fff;

	--color-success: #28A745;
	--color-error: #DC3545;
	--color-warning: #FFC107;
	--color-information: #007BFF;
}

* {
	padding: 0;
	margin: 0;
}

body {
	color: var(--brand-primary);
}

::-webkit-scrollbar {
    display: none
}