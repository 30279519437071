.sound {
	width: 100%;
	height: 110px;
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
	vertical-align: middle;
	position: relative;
}	

.sound .iconSound {
	height: 90px;
	z-index: 200;
	fill: var(--brand-third);
	transition: height 2s;
}

.sound .iconSound.active {
	height: 60px;
	transition: height 2s;
}

.sound .circleSound {
	width: 60px;
	height: 60px;
	position: absolute;
	background-color: transparent;
	border: 2px solid var(--brand-third);
	border-radius: 50%;
	transform: scale(1);
	opacity: 0;
	z-index: 100;
}

.sound .circleSound.one.active {
	animation: sound 1s linear infinite;
}

.sound .circleSound.two.active {
	animation: sound 3s linear infinite;
}

.sound .circleSound.three.active {
	animation: sound 5s linear infinite;
}

@keyframes sound {
	from {
		transform: scale(1);
		transition: transform 3s;
		opacity: 1;
	}
	to {
		transform: scale(2);
		opacity: 0;
		transition: opacity 3s;
	}
}