.player {
    width: 100%;
    height: 60px;
    background-color: var(--brand-third);
    border-radius: 60px;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    vertical-align: middle;
    overflow: hidden;
    color: var(--brand-primary);
    user-select: none;
}

.player .buttonDefaultPlayer {
    border: none;
	border-radius: 4px;
	cursor: pointer;
	background-color: transparent;
	padding: 4px 12px;
	color: var(--light-one);
	margin: 2px;
	height: 50px;
    color: var(--brand-primary);
}

.player .displayPlayer {
    width: 50px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
}